import { defineComponent, type PropType } from 'vue'

import { INVALID_KEYS, TIMEOUT_DEFAULT } from '@/javascript/vuejs/helpers/_constants'
import { autoInitMdc } from '@/javascript/vuejs/helpers/autoInitMDC'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import {
	debounce,
	type WatchCallback,
	type WatcherFunction,
} from '@/javascript/vuejs/utils/globalUtils'
import { appendRandomString } from '@/javascript/vuejs/utils/stringUtils'

/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	'blur': (): boolean => true,
	'change': (): boolean => true,
	'enter': (key: KeyboardEvent): boolean => true,
	'escape': (): boolean => true,
	'focus': (): boolean => true,
	'input': (): boolean => true,
	'keypress': (): boolean => true,
}
/* eslint-enable */

export default defineComponent({
	name: 'InputDefault',

	props: {
		...on(emits),

		additionalClass: { default: '', type: String },
		additionalLabelClasses: { default: '', type: String },
		autofocus: { default: false, type: Boolean },
		dataTest: { default: '', type: String },
		error: { default: '', type: String },
		hasTooltip: { default: false, type: Boolean },
		icon: { default: '', type: String },
		id: { default: '', type: String },
		infoNotice: { default: '', type: String },
		isDisabled: { default: false, type: Boolean },
		labelText: { default: '', type: String },
		max: { optional: true, type: Number },
		maxlength: { optional: true, type: Number },
		min: { optional: true, type: Number },
		name: { default: '', type: String },
		pattern: { default: '', type: String },
		placeholder: { default: '', type: String },
		reference: { optional: true, type: String },
		required: { default: false, type: Boolean },
		setReadonly: { default: false, type: Boolean },
		theme: { default: '', type: String },
		tooltipText: { default: '', type: String },
		type: { default: 'text', type: String as PropType<InputOptions> },
		value: { optional: true, type: [String, Number] },
	},

	data(): Data {
		return {
			hasError: false,
			inputModel: null,
			isFocused: false,
			observer: null,
			unwatch: null,
		}
	},

	computed: {
		appendRandomString(): string {
			return appendRandomString()
		},

		isNumber(): boolean {
			return this.type === 'number' && (!!this.max || !!this.min)
		},

		setThemeBackground(): string {
			switch (this.theme) {
				case 'isSimple':
					return 'mdc-text-field--simple-background'

				case 'isDisabled':
					return 'mdc-text-field--disabled'

				default:
					return ''
			}
		},
	},

	watch: {
		value() {
			this.updateValue()
		},
	},

	created() {
		const debouncedSearchString = debounce(() => { this.handleChange() }, TIMEOUT_DEFAULT) as WatchCallback

		this.unwatch = this.$watch('inputModel', debouncedSearchString)
	},

	beforeDestroy() {
		this.unwatch?.()
	},

	mounted() {
		autoInitMdc(this.$el)

		if (this.value || this.value === 0) {
			this.updateValue()
		}

		const element = document.getElementById(this.id)

		this.observer = new MutationObserver(mutations => {
			for (const mutation of mutations) {
				if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
					if (element) {
						const isInvalid = element.classList.contains('mdc-text-field--invalid')

						this.hasError = isInvalid
					}
				}
			}
		})

		if (element) {
			this.$nextTick(() => {
				this.observer?.observe(element, {
					attributes: true,
					childList: false,
					subtree: false,
				})
			})
		}
	},

	destroyed() {
		if (this.observer) {
			this.observer.disconnect()
		}
	},

	methods: {
		handleBlur(): void {
			this.isFocused = false
			this.$emit('focus')
		},

		handleChange(): void {
			if (this.isNumber && this.inputModel) {
				if (!!this.min && Number(this.inputModel) < this.min) {
					this.inputModel = this.min
				}

				if (!!this.max && Number(this.inputModel) > this.max) {
					this.inputModel = this.max
				}

				this.inputModel = Number(this.inputModel)
			}

			this.handleInput()
		},

		handleChangeEvent(): void {
			if (this.isNumber && this.inputModel) {
				if (!!this.min && Number(this.inputModel) < this.min) {
					this.inputModel = this.min
				}

				if (!!this.max && Number(this.inputModel) > this.max) {
					this.inputModel = this.max
				}

				this.inputModel = Number(this.inputModel)
			}

			this.$emit('change', this.inputModel)
		},

		handleEscape(): void {
			this.$emit('escape')
		},

		handleFocus(): void {
			this.isFocused = true
			this.$emit('focus')
		},

		handleInput(): void {
			this.$emit('input', typeof this.inputModel === 'string' ? this.inputModel : Number(this.inputModel))
			this.$emit('blur')
		},

		handleKeypress(key: KeyboardEvent): undefined | void {
			if (key) {
				this.$nextTick(() => {
					if (this.type === 'number' && INVALID_KEYS.includes(key.key)) {
						key.preventDefault()
						return false
					}

					if (key.key === 'Escape') {
						this.handleEscape()
					}

					if (key.key === 'Enter') {
						this.$emit('enter', key)
					}
				})
			}
		},

		updateValue(): void {
			if (this.value || this.value === 0) {
				this.inputModel = typeof this.value === 'string' ? this.value : Number(this.value)
			}
		},
	},

	render() {
		return (
			<div class={['input-line input-line--full-width', this.additionalClass]}>
				<label
					class={[
						'input-line__textfield',
						'mdc-text-field',
						'mdc-text-field--filled',
						'mdc-ripple-upgraded',
						'mdc-text-field--label-floating',
						this.additionalLabelClasses,
						this.setThemeBackground,
						this.hasError ? 'mdc-text-field--invalid' : '',
						this.hasTooltip ? 'js-tooltip' : '',
						this.icon ? 'mdc-text-field--with-leading-icon' : '',
						!this.isFocused ? 'mdc-text-field--readonly' : '',
					]}
					data-mdc-auto-init="MDCTextField"
					data-tippy-content={this.tooltipText}
					id={this.id}
				>
					<div class="mdc-text-field__ripple" />
					{!!this.icon &&
						<i
							class={[
								'mdc-text-field__icon',
								'mdc-text-field__icon--leading',
								'fa',
								this.icon,
							]}
							aria-hidden="true"
						/>
					}

					<input
						v-model={this.inputModel}
						aria-labelledby={this.labelText}
						autocomplete={this.appendRandomString}
						autofocus={this.autofocus}
						class="mdc-text-field__input"
						data-test={this.dataTest}
						disabled={this.theme === 'isDisabled'}
						max={this.isNumber ? this.max : undefined}
						maxlength={this.maxlength}
						min={this.isNumber ? this.min : undefined}
						name={this.name}
						pattern={this.pattern || undefined}
						placeholder={this.placeholder}
						readonly={!this.isDisabled && !this.isFocused && this.setReadonly}
						ref={this.reference}
						required={this.required}
						type={this.type}
						onBlur={this.handleBlur}
						onChange={this.handleChangeEvent}
						onFocus={this.handleFocus}
						onInput={this.isNumber ? this.handleChange : this.handleInput}
						onKeydown={this.handleKeypress}
					/>

					<span
						id={this.id}
						class={[
							'mdc-floating-label',
							{ 'mdc-floating-label--float-above': this.inputModel || this.isFocused },
							{ 'mdc-floating-label--required': this.required },
						]}
					>
						{this.labelText}
					</span>

					<div class="mdc-line-ripple" />
				</label>

				{this.hasError &&
					<p
						class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg"
						data-test="input-default-error-message"
					>
						{this.error}
					</p>
				}

				{!!this.infoNotice &&
					<p class="info-notice info-notice--small info-notice--small-text">
						{this.infoNotice}
					</p>
				}
			</div>
		)
	},
})

interface Data {
	hasError: boolean
	inputModel: number | string | null
	isFocused: boolean
	observer: MutationObserver | null
	unwatch: WatcherFunction | null
}

type InputOptions
	= 'email'
	| 'number'
	| 'password'
	| 'text'
